<template>
  <component :is="appropriateComponent" />
</template>

<script>
import SharedPageMixin from "@/mixins/SharedPageMixin";

export default {
  components: {
    AdminComponent: () => import("@/pages/admin/system/notice/NoticeView"),
    UserComponent: () => import("@/pages/user/system/notice/NoticeView"),
  },
  mixins: [SharedPageMixin],
};
</script>
